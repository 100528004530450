import React from 'react';
import './Header.scss';
import { Section } from 'react-scroll-section';
//import Alert from '../alert/Alert';
//import WelcomeBlock from './welcomeBlock/WelcomeBlock';
import StoreCtaBlock from './storeCtaBlock/StoreCtaBlock';
import PredictionBlock from './predictionBlock/PredictionBlock';
import ExplanationBlock from './explanationBlock/ExplanationBlock';
//import QRCodeBlock from '../qrcodeBlock/QRCodeBlock';

const Header = ({
  data,
  theme
}) => {
  return (
    <div 
      className={theme === 'blue' ? 'headerBlue' : 'headerGreen'}>
      <Section id="headerSection">
        <div className='headerContent'>
          {
          //<Alert 
          //  text_color={text_color}
          //  status='basic-control-transparent-200'/>
          }
          <div className='headerRow' id='rowOne'>
            <StoreCtaBlock/>
            {
            //<WelcomeBlock
            //  text_color={text_color}/>
            }
            <PredictionBlock
              data={data}/>
          </div>
          <div className='headerRow'>
            <ExplanationBlock
              make={data.make}
              model={data.model}
              age={data.age}
              rating={data.rating}
            />
            {
            //<QRCodeBlock/>
            }
          </div>
        </div>
      </Section>
    </div>
  )
}

export default Header