import React from 'react';
import './PredictionBlock.scss';
import SummarizeBlock from './summarizeBlock/SummarizeBlock';
import WidgetBlock from './widgetBlock/WidgetBlock';

const PredictionBlock = ({
  data
}) => {
  const badge_list = []// ['mini car', 'convertible']
  return (
    <div className='predictionBlock'>
      <WidgetBlock 
        rating={data.rating}
        price={data.price}
        price_range={data.range}
        make={data.make}
        model={data.model}
        year={data.manufacture_year}
        fuel={data.fuel}
        power={data.power}
        cubic_capacity={data.cubic_capacity}
        badge_list={badge_list}/>
      <SummarizeBlock
        make={data.make}
        mileage={data.mileage}
        run_rating={data.run_rating}
        value_retention={data.value_retention}/>
    </div>
  )
}

export default PredictionBlock