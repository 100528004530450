import React from 'react';
import './CarMakeModelBlock.scss';
import Text from '../text/Text';
import Badge from '../badge/Badge';

const CarMakeModelBlock = ({
  make,
  model,
  year,
  fuel,
  power,
  cubic_capacity,
  badge_list,
  type = 'row'
}) => {
  const badgeItems = badge_list.map((badge) =>
    <Badge status='basic-300'>
      <Text category='c2' status='primary-500'>
        {badge}
      </Text>
    </Badge>
  );
  return (
    <div className='carBlock'>
      { type == 'row' ? 
        <div className='carMakeModelBlock'>
          <div className='carMakeModel'>
            <Text category='h4'>
              {make}
            </Text>
            <Text category='s1'>
              {model}
            </Text>
          </div>
          <div className='badgeBlock'>
            {badgeItems}
          </div>
        </div> : 
        <div className='carMakeModelBlockCol'>
          <div className='carMakeModel'>
            <Text category='h4'>
              {make}
            </Text>
            <Text category='s1'>
              {model}
            </Text>
            <div className='badgeBlock'>
              {badgeItems}
            </div>
          </div>
        </div>
      }
      <div className='carDataBlock'>
        <Text category='s2'>
          {year}
        </Text>
        <Text category='s2'>|</Text>
        <Text category='s2'>
          {fuel}
        </Text>
        <Text category='s2'>|</Text>
        <Text category='s2'>
          {cubic_capacity} cm3
        </Text>
        <Text category='s2'>|</Text>
        <Text category='s2'>
          {power} LE
        </Text>
      </div>
    </div>
  )
}

export default CarMakeModelBlock