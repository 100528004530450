import React, {useEffect} from 'react';
import './SummarizeBlock.scss';
import Text from '../../../text/Text';
import * as eva from 'eva-icons';
import { Tooltip } from 'antd';

const PredictionBlock = ({
  make,
  mileage,
  run_rating,
  value_retention,
  type = 'light'
}) => {
  useEffect(() => {
    eva.replace();
  }, []);

  const mileage_level_object = {
    'Low': {
      text: 'Alacsony',
      color: 'green'
    },
    'Medium': {
      text: 'Közepes',
      color: 'black'
    },
    'High': {
      text: 'Magas',
      color: 'red'
    },
    'Cannot be calculate': {
      text: 'Nincs adat',
      color: 'grey'
    }
  }
  const value_retention_object = {
    'Low': {
      text: 'Alacsony',
      color: 'red'
    },
    'Medium': {
      text: 'Közepes',
      color: 'black'
    },
    'High': {
      text: 'Magas',
      color: 'green'
    },
    'Cannot be calculate': {
      text: 'Nincs adat',
      color: 'grey'
    }
  }
  return (
    <div className={`summarizeBlock ${type}`}>
      <div className='summaRow'>
        <i
          data-eva="bar-chart-outline"
          data-eva-fill="black"
          style={{marginRight: "10px", marginTop: "auto", marginBottom: "12px"}}
        />
        <div className='summarizeRow'>
          <Text category='label'>
            Futott km
          </Text>
          <Text category='h6'>
            {mileage} Km
          </Text>
        </div>
      </div>
      <div className='summaRow'>
        <i
          data-eva="bar-chart-2-outline"
          data-eva-fill="black"
          style={{marginRight: "10px", marginTop: "auto", marginBottom: "12px"}}
        />
        <Tooltip 
          title={
            run_rating == 'High' ? 
          `Ennek az autónak a futásteljesítménye magas, vagyis a kilométeróra adatai alapján többet használták, mint az ennyi idős autókat átlagosan szokták.` :
          run_rating == 'Low' ?
          `Ennek az autónak a futásteljesítménye alacsony, vagyis a kilométeróra adatai alapján kevesebbet használták, mint az ennyi idős autókat átlagosan szokták.` :
          `Ennek az autónak a futásteljesítménye átlagos, vagyis a kilométeróra adatai alapján ugyanannyit használták, mint az ennyi idős autókat átlagosan szokták.`
          }>
          <div className='summarizeRow'>
            <Text category='label'>
              Futásteljesítmény
            </Text>
            <Text category='h6' style={{color: mileage_level_object[run_rating].color}}>
              {mileage_level_object[run_rating].text}
            </Text>
          </div>
        </Tooltip>
      </div>
      <div className='summaRow'>
        <i
          data-eva="trending-down-outline"
          data-eva-fill="black"
          style={{marginRight: "10px", marginTop: "auto", marginBottom: "12px"}}
        />
        <Tooltip 
          title={
            value_retention == 'High' ? 
          `Az átlagos ${make} értéktartása magas, ami azt jelenti, hogy jobban megőrzi az értékét (kevesebbet veszít az értékéből), mint a hazai forgalomban lévő átlagos személyautó.` :
          value_retention == 'Low' ?
          `Az átlagos ${make} értéktartása alacsony, ami azt jelenti, hogy kevésbé tartja az értékét, mint a hazai forgalomban lévő átlagos személyautó.` :
          `Az átlagos ${make} értéktartása átlagos, ami azt jelenti, hogy hasonló mértékben tartja az értékét, mint a hazai forgalomban lévő átlagos személyautó.`
          }>
          <div className='summarizeRow'>
            <Text category='label'>
              Értéktartás
            </Text>
            <Text category='h6' style={{color: value_retention_object[value_retention].color}}>
              {value_retention_object[value_retention].text}
            </Text>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default PredictionBlock