import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import App from './App';
import store from './state/store';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: 'GTM-WXWHMM2'
};

TagManager.initialize(tagManagerArgs);

const pathArray = window.location.pathname.split('/');
const advert_id = pathArray[pathArray.length - 1];

ReactDOM.render(
  <Provider store={store}>
    <App advert_id={advert_id}/>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
