import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const instacarApi = createApi({
  reducerPath: 'instacarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://widget-service.instacar-gateway.eu/',
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', '*')
      return headers
    },
  }),
  tagTypes: ['Car'],
  //keepUnusedDataFor: 30,
  endpoints: () => ({})
});
export default instacarApi;