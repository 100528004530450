import React from 'react';
import './Text.scss';

const Text = ({
  category,
  status,
  id,
  style,
  children
}) => {

  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 's1', 's2', 'p1', 'p2', 'c1', 'c2', 'label'];
  const safeHeading = category ? category.toLowerCase() : '';
  let Text = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';
  
  switch(Text) {
    case 'p1':
      Text = 'p';
      break;
    case 'p2':
      Text = 'p';
      break;
    case 's1':
      Text = 'div';
      break;
    case 's2':
      Text = 'div';
      break;
    case 'c1':
      Text = 'div';
      break;
    case 'c2':
      Text = 'div';
      break;
  }

  const validStatus = [
    'primary-100', 'primary-200', 'primary-300', 'primary-400', 'primary-500', 'primary-600', 'primary-700', 'primary-800', 'primary-900', 
    'success', 
    'info', 
    'warning', 
    'danger', 
    'basic-100', 'basic-200', 'basic-300', 'basic-400', 'basic-500', 'basic-600', 'basic-700', 'basic-800', 'basic-900', 'basic-1000', 'basic-1100',
    'control'
  ];
  const safeStatus = status ? status.toLowerCase() : '';
  const Status = validStatus.includes(safeStatus) ? safeStatus : 'basic-800';

  const typeClass = Text != 'p' || Text != 'div' ? category : ''

  return (
    <Text
      {... id ? {id: id} : {}}
      className={`${Status} ${typeClass}`} 
      style={style}
    >
      {children}
    </Text>
  )
}

export default Text