import React, {useEffect, useState} from 'react';
import './Education.scss';
import * as eva from 'eva-icons';
import Text from '../text/Text';
import educationContent from '../../educationContent';
import { Button } from 'antd';

const Education = ({
  theme
}) => {
  const [textValue, setTextValue] = useState('');

  const changeTextValue = () => {
    const len = educationContent.length;
    setTextValue(educationContent[Math.floor(Math.random() * len)])
  }

  useEffect(() => {
    eva.replace();
    changeTextValue();
  }, []);

  return (
    <div className='education' 
      style={{backgroundColor: '#4149DF'}}>
      <div className='educationBlock'>
        <div className='educationContent'>
          <div className='educationContent_left'>
            <Text
              category='h1' 
              style={{ color: 'white' }}>
              Tudtad?
            </Text>
          </div>
          <div className='educationContent_right'>
            <div className='educationContent_right_top'>
              <div className='alertIcon'>
              <i  
                data-eva="alert-circle-outline" 
                data-eva-fill="white"></i>
              </div>
              <Text 
                category='p1'
                style={{ color: 'white' }}>
                {textValue?.content}
              </Text>
            </div>
            <div className='educationContent_right_bottom'>
              <Text 
                category='c2' 
                style={{ color: 'white' }}>
                {textValue?.source}
              </Text>
            </div>
          </div>
        </div>
        <Button 
          shape="round"
          size="large"
          ghost
          onClick={()=>changeTextValue()}
          style={{ float: 'right', marginRight: '30px' }}>
          MÉG TÖBBET AKAROK TUDNI
        </Button>
      </div>
    </div>
  )
}

export default Education