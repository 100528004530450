import React, {useEffect, useState} from 'react';
import './Top.scss';
import logo from '../../image/logo.svg';
import Text from '../text/Text';
import * as eva from 'eva-icons';
//import { useScrollSection } from 'react-scroll-section';

const Top = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState('');
  /*
  const headerSection = useScrollSection('headerSection');
  const formSection = useScrollSection('formSection');
  const resultListSection = useScrollSection('resultListSection');
  */
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  useEffect(() => {
  }, [isMobile]);
  useEffect(() => {
    eva.replace();
  }, []);
  
  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
      setIsOpen('')
    }
  }
  /*
  const handleOnclick=(menu)=>{
    if(menu === 'headerSection'){
      headerSection.onClick()
      if(isMobile){
        setIsOpen();
      }
    }
    else if(menu === 'formSection'){
      formSection.onClick()
      if(isMobile){
        setIsOpen();
      }
    }
    else if (menu === 'resultListSection'){
      resultListSection.onClick()
      if(isMobile){
        setIsOpen();
      }
    }
  }
  */
  const handleOpenMenu=()=>{
    console.log('open')
    setIsOpen('menuOpen')
  }
  return (
    <div className='top'>
      <div className='topContent'>
        <div
          onClick={()=>{handleOpenMenu()}}
          className='hamburgerMenu'>
          <i
            data-eva="menu-outline"
            data-eva-fill="white"
            style={{marginRight: "10px", marginLeft: "10px", marginTop: "10px"}}
          />
        </div>
        <a href='https://instacar.hu'>
          <img src={logo} className="logo" alt="logo" />
        </a>
        <div className={`navigation ${isOpen}`}>
          {
          //<a href='#header' onClick={()=>handleOnclick('headerSection')}>
          //  <Text category={headerSection.selected ? 's1' : 's2'} status='basic-100'>
          //    Értékelt autó
          //  </Text>
          //</a>
          //<a href='#form' onClick={()=>handleOnclick('formSection')}>
          //  <Text category={formSection.selected ? 's1' : 's2'} status='basic-100'>
          //    Kereső
          //  </Text>
          //</a>
          //c
          //  <Text category={resultListSection.selected ? 's1' : 's2'} status='basic-100'>
          //    Hasonló autók
          //  </Text>
          //</a>
          }
          <a href='https://instacar.hu'>
            <Text category='s2' status='basic-100'>
              Ismerj meg minket
            </Text>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Top