import React from 'react';
import './ExplanationBlock.scss';
import Text from '../../text/Text';

const ExplanationBlock = ({
  make,
  model,
  age,
  rating
}) => {
  let level;
  let level_text;
  switch(rating) {
    case "Very good price":
      level = "low";
      level_text = "lényegesen kedvezőbb";
      break;
    case "Good price":
      level = "medium_low";
      level_text = "kedvezőbb";
      break;
    case "Fair price":
      level = "medium";
      level_text = "annyi";
      break;
    case "Increased price":
      level = "medium_high";
      level_text = "magasabb";
      break;
    case "High price":
      level = "high";
      level_text = "lényegesen magasabb";
      break;
  }

  return (
    <div className='explanationBlock'>
      <div className='explanationContent'>
        <div className='explanationContentColumn'>
          <Text 
            category='h4'
            style={{paddingBottom: 20}}>
            Az általad megnézett {age} éves <br/>
            {make} {model} ára {level_text}, <br/>
            mint egy átlagos állapotú hasonló korú {make} {model}.
          </Text>
          { level === 'low' ?
          <Text category='p1'>
            Az alacsony árnak számos magyarázata lehet.<br/>
            Ellenőrizd az alábbiakat:<br/>
              <ul>
                <li>az autó műszaki, esztétikai állapota az átlag alatt van-e,</li>
                <li>elmaradtak-e lényeges karbantartások, amelyeket neked kell majd átvállalni,</li>
                <li>követhető-e az autó előélete, esetleg külföldről használtan hozták-e be,</li>
                <li>az eladó számára fontos-e a gyors eladás.</li>
              </ul>
            Javasoljuk, hogy az autót mindenképpen nézesd meg egy szakemberrel, vagy vidd el egy szervizbe, hogy a felmérhető műszaki állapotáról legyen egy objektív képed, mielőtt döntenél.
          </Text> : null }
          { level === 'medium_low' ?
          <Text category='p1'>
            A kedvező árnak számos magyarázata lehet.<br/>
            Ellenőrizd az alábbiakat:<br/>
              <ul>
                <li>az autó műszaki, esztétikai állapota valamivel az átlag alatt lehet,</li>
                <li>elmaradtak-e bizonyos karbantartások, amelyeket neked kell majd átvállalni,</li>
                <li>követhető-e az autó előélete, esetleg külföldről használtan hozták-e be,</li>
                <li>az eladó számára fontos-e a gyors eladás.</li>
              </ul>
            Javasoljuk, hogy az autót mindenképpen nézesd meg egy szakemberrel, vagy vidd el egy szervizbe, hogy a felmérhető műszaki állapotáról legyen egy objektív képed, mielőtt döntenél.
          </Text> : null }
          { level === 'medium' ?
          <Text category='p1'>
            Az átlagos árnak számos magyarázata lehet.<br/>
            Ellenőrizd az alábbiakat:<br/>
              <ul>
                <li>az autó műszaki, esztétikai állapota a korának megfelelő-e,</li>
                <li>elvégeztek-e rajta minden lényeges karbantartást,</li>
                <li>követhető-e az autó előélete.</li>
              </ul>
            Javasoljuk, hogy az autót mindenképpen nézesd meg egy szakemberrel, vagy vidd el egy szervizbe, hogy a felmérhető műszaki állapotáról legyen egy objektív képed, mielőtt döntenél.
          </Text> : null }
          { level === 'medium_high' ?
          <Text category='p1'>
            Az átlag feletti árnak számos magyarázata lehet.<br/>
            Ellenőrizd az alábbiakat:<br/>
              <ul>
                <li>az autó műszaki, esztétikai állapota lényegesen jobb-e, mint az átlag,</li>
                <li>minden korának megfelelő karbantartást elvégeztek-e rajta, a megfelelő alkatrészeket gyáriakkal cserélték-e,</li>
                <li>követhető-e az autó előélete,</li>
                <li>az eladó számára talán nem sürgős az eladás ezért kitartja az autó árát.</li>
              </ul>
            Javasoljuk, hogy az autót mindenképpen nézesd meg egy szakemberrel, vagy vidd el egy szervizbe, hogy a felmérhető műszaki állapotáról legyen egy objektív képed, mielőtt döntenél.
          </Text> : null }
          { level === 'high' ?
          <Text category='p1'>
            A magas árnak számos magyarázata lehet.<br/>
            Ellenőrizd az alábbiakat:<br/>
              <ul>
                <li>az autó műszaki, esztétikai állapota az átlag alatt van-e,</li>
                <li>elmaradtak-e lényeges karbantartások, amelyeket neked kell majd átvállalni,</li>
                <li>követhető-e az autó előélete, esetleg külföldről használtan hozták-e be,</li>
                <li>az eladó számára fontos-e a gyors eladás.</li>
              </ul>
            Javasoljuk, hogy az autót mindenképpen nézesd meg egy szakemberrel, vagy vidd el egy szervizbe, hogy a felmérhető műszaki állapotáról legyen egy objektív képed, mielőtt döntenél.
          </Text> : null }
        </div>
      </div>
    </div>
  )
}

export default ExplanationBlock;