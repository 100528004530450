import React from 'react';
import './StoreCtaBlock.scss';
import Text from '../../text/Text';
import googleplaybadge from '../../../image/google-play-badge.png';
import appstorebadge from '../../../image/app-store-badge.png';

const StoreCtaBlock = () => {
  return (
    <div className='storeCtaBlock'>
      <div className='storeCtaPhoneBlock'>
        <img 
          src='https://joautok-widget-public.s3.eu-central-1.amazonaws.com/mobile.png' 
          alt="phone" 
          className='ctaPhone'/>
      </div>
      <div className='storeCtaContent'>
        <Text 
          category='h1' 
          status='basic-100'>
          Nézd meg az autó pontos értékét!
        </Text>
        <Text 
          category='s1' 
          status='basic-100'
          style={{paddingTop: 10, paddingBottom: 10}}>
          Használtautó értékbecslés most ingyen
        </Text>
        <ul className='ctaContentList'>
          <li>Rendszám alapján, két gombnyomásra</li>
          <li>Már 294 modellre</li>
          <li>15 évesnél fiatalabb személyautók esetén</li>
        </ul>
        <div 
          className='starBadge'>
          <div className='starBadgeIcon'>
            <Text 
              category='h5' 
              status='basic-800'
              style={{margin: 0}}>
              Új!
            </Text>
          </div>
          <Text 
            category='p2' 
            status='basic-800'
            style={{margin: 0}}>
            Átírási költség kalkulátor az appban
          </Text>
        </div>
        <a
          className='storeBtn'
          id='cilckBtnGooglePlayStore' 
          onClick={()=>{ window.dataLayer.push({'event': 'cilckToBtnGooglePlayStore'}) }}
          href="https://play.google.com/store/apps/details?id=com.rolandgoreczky.Instacar" target="_blank" data-category="App áruház" data-title="Google Play" title="Instacar Google Play - Android"> 
					<img src={googleplaybadge} className="imgFluid" alt="Google Play"/>
				</a>
        <a
          className='storeBtn'
          id='cilckToBtnAppleAppStore'
          onClick={()=>{ window.dataLayer.push({'event': 'cilckToBtnAppleAppStore'}) }}
          href="https://apps.apple.com/hu/app/instacar/id6443874337" target="_blank" data-category="App áruház" data-title="App Store" title="Instacar App Store - iOS"> 
					<img src={appstorebadge} className="imgFluid" alt="App Store"/>
				</a>
      </div>
    </div>
  )
}

export default StoreCtaBlock