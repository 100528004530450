//import AsyncStorage from '@react-native-async-storage/async-storage'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import instacarApi from '../service/instacarApi'

const store = configureStore({
  reducer: {
    [instacarApi.reducerPath]: instacarApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([instacarApi.middleware]);
  }
})

setupListeners(store.dispatch)

export default store