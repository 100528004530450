import React from 'react';
import './Badge.scss';

const Badge = ({
  status,
  children
}) => {
  const validStatus = [
    'primary', 
    'success', 
    'info', 
    'warning', 
    'danger', 
    'basic-100', 'basic-200', 'basic-300', 'basic-400', 'basic-500', 'basic-600', 'basic-700', 'basic-800', 'basic-900', 'basic-1000', 'basic-1100',
    'control'
  ];
  const safeStatus = status ? status.toLowerCase() : '';
  const Status = validStatus.includes(safeStatus) ? safeStatus : 'basic-800';

  return (
    <div className={`badge ${Status}`}>
      {children}
    </div>
  )
}

export default Badge