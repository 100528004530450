import React from 'react';
import './WidgetBlock.scss';
import CarMakeModelBlock from '../../../carMakeModelBlock/CarMakeModelBlock';
import Text from '../../../text/Text';

const WidgetBlock = ({
  rating,
  price,
  price_range,
  make,
  model,
  year,
  fuel,
  power,
  cubic_capacity,
  badge_list
}) => {
  let level;
  let rating_text;
  let bcg_color;
  let text_color;
  switch(rating) {
    case "Very good price":
      level = "low";
      rating_text = "Alacsony ár";
      bcg_color = '#FCCB64';
      text_color = '#222B45';
      break;
    case "Good price":
      level = "medium_low";
      rating_text = "Átlag alatti";
      bcg_color = '#51D68B';
      text_color = '#222B45';
      break;
    case "Fair price":
      level = "medium";
      rating_text = "Átlagos ár";
      bcg_color = '#108667';
      text_color = '#FFFFFF';
      break;
    case "Increased price":
      level = "medium_high";
      rating_text = "Átlag feletti ár";
      bcg_color = '#51D68B';
      text_color = '#222B45';
      break;
    case "High price":
      level = "high";
      rating_text = "Magas ár";
      bcg_color = '#FCCB64';
      text_color = '#222B45';
      break;
  }
  return (
    <div className={`widgetBlock ${level}`}>
      <div className='widgetPriceBox'>
        <Text 
          category='h4' 
          style={{color: text_color}}>
          {rating_text}
        </Text>
        <Text 
          category='h6' 
          sstyle={{color: text_color}}>
          {new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 }).format(price)}
        </Text>
      </div>

      <div className='widgetPriceRange'>

        <div className='widgetColumn low'>
          <div className='pin'></div>
          <div className='widgetSticker'></div>
          <Text category='c2'>
            {price_range.very_good.low/1000000} - {price_range.very_good.high/1000000} Mft
          </Text>
        </div>

        <div className='widgetColumn medium_low'>
          <div className='pin'></div>
          <div className='widgetSticker'></div>
          <Text category='c2'>
            {price_range.good.low/1000000} - {price_range.good.high/1000000} Mft
          </Text>
        </div>
        <div className='widgetColumn medium'>
          <div className='pin'></div>
          <div className='widgetSticker'></div>
          <Text category='c2'>
            {price_range.fair.low/1000000} - {price_range.fair.high/1000000} Mft
          </Text>
        </div>
        <div className='widgetColumn medium_high'>
          <div className='pin'></div>
          <div className='widgetSticker'></div>
          <Text category='c2'>
            {price_range.increased.low/1000000} - {price_range.increased.high/1000000} Mft
          </Text>
        </div>
        <div className='widgetColumn high'>
          <div className='pin'></div>
          <div className='widgetSticker'></div>
          <Text category='c2'>
            {price_range.high.low/1000000} - {price_range.high.high/1000000} Mft
          </Text>
        </div>
      </div>

      <CarMakeModelBlock
        make={make}
        model={model}
        year={year}
        fuel={fuel}
        power={power}
        cubic_capacity={cubic_capacity}
        badge_list={badge_list}
      />
    </div>
  )
}

export default WidgetBlock