const educationContent = [
  {
    content: 'Hazánkban 6-7x annyi használt autó talál gazdára évente, mint új (800 ezer, 120-130 ezer). Az elmúlt időszak kedvezőtlen gazdasági hatásainak következtében ráadásul a használtautók iránt érdeklődés további növekedése várható, ami az árak emelkedését vonja magával.',
    source: 'Instacar'
  },
  {
    content: 'Egy dízel személyautó 25 ezer kilométert, egy benzines 15 ezer kilométert fut életciklusa első 3-4 évében átlagosan. Minél idősebb egy autó annál alacsonyabb éves futásteljesítménnyel érdemes számolni.',
    source: 'Adatgyűjtés és elemzés a használt személy- és kishaszongépjárművek piacának jobb megértése érdekében… - Transport&Mobility - 2016. január'
  },
  {
    content: 'A vásárlók szerint az autó kiválasztásánál az ár (64%), kilométerfutás (35%), gyártó (27%) és a műszaki állapot (26%) a legfontosabb szempontok. Az ár után a jármű futásteljesítménye a fő tényező, amely befolyásolja az autó értékét a használtautó-piaci árat és a használtautó értékcsökkenésének mértékét.',
    source: 'Európai Bizottság'
  },
  {
    content: 'Minden második külföldről behozott autó esetén történhet visszaélés a kilométerszámlálókkal az EU felmérése szerint.',
    source: 'A gépjárművek kilométer-számlálójának manipulálása az EU-ban - European Parlamentary Research Service - 2018. január'
  },
  {
    content: 'Egy átlagos használt személyautónak 3-4 tulajdonosa van az életciklusa alatt. Egy-egy tulajdonos jellemzően 4-7 évig használja az autót.',
    source: 'Adatgyűjtés és elemzés a használt személy- és kishaszongépjárművek piacának jobb megértése érdekében… - Transport&Mobility - 2016. január'
  },
  {
    content: 'Magyarországon több mint 4 millió személyautó volt forgalomban. 28% legfeljebb 10 éves, 57% 10 és 20 év közötti, 15% ennél is idősebb.',
    source: 'Központi Statisztikai Hivatal - Eurostat'
  },
  {
    content: 'Az Európai Unióban az ajánlatok összehasonlíthatósága a használtautó kereskedelemben a legrosszabb az EB kutatása szerint. Az Instacar segít az árak összehasonlításában.',
    source: 'Európai Bizottság - Piaci Teljesítmény Mutató'
  },
  {
    content: '2021-ben több külföldről behozott használt személyautót helyeztek Magyarországon forgalomba (129.050 db), mint amennyi újat (125.114 db).',
    source: 'STADAT - Központi Statisztikai Hivatal'
  },
  {
    content: 'A Magyarországon 2021-ben első alkalommal forgalomba helyezett autók közel fele 6 évesnél idősebben került behozatalra.',
    source: 'STADAT - Központi Statisztikai Hivatal'
  },
  {
    content: 'Az alábbi kéréseket érdemes megválaszolni autóvásárlás előtt: mire és mennyit használom, mennyien használjuk, tartja-e a kiválasztott autó az értékét, mennyi fut az utakon belőle, lesz-e hozzá alkatrész, mennyi havi költséget fog jelenteni számomra az autó az árán túl? Az Instacar segít jól választani.',
    source: 'Instacar'
  }
]

export default educationContent;