import instacarApi from './instacarApi'

export const carApi = instacarApi.injectEndpoints({
  endpoints: (build) => ({
    calcPrice: build.mutation({
      query: ({ data }) => ({
        url: `car/${data.advert_id}`,
        method: 'GET'
      })
    })
  })
})

export const { 
  useCalcPriceMutation
} = carApi