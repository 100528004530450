import React, { useState, useEffect } from 'react';
import './App.scss';
import { ScrollingProvider } from 'react-scroll-section';
import Top from './component/top/Top';
import Header from './component/header/Header';
//import Body from './component/body/Body';
import Footer from './component/footer/Footer';
import FooterCTA from './component/footerCTA/FooterCTA';
import Education from './component/education/Education';
import { useCalcPriceMutation } from './service/car';

function App({
  advert_id
}) {
  const [calcPrice, { isLoading, data, error: calcPriceError }] = useCalcPriceMutation();
  const [isVisible, setIsVisible] = useState(false);
  const [textColor, setTextColor] = useState('basic-100');
  const [bcgColor,  setBcgColor] = useState('#17A06E');
  const [circleColor,  setCircleColor] = useState('#17A06E');
  const [theme,  setTheme] = useState('blue');

  useEffect(() => {
    calcPrice({
      "data" : {
        "advert_id": advert_id
      }
    })
  }, [advert_id, calcPrice]);
  
  useEffect(() => {
    if(data && data?.rating !== 'Out of range'){
      switch(data.rating) {
        case "Very good price":
          setTheme('blue');
          setBcgColor('#4149DF');
          setCircleColor('#4149DF');
          setTextColor('basic-100');
          break;
        case "Good price":
          setTheme('green');
          setBcgColor('#17A06E');
          setCircleColor('#4149DF');
          setTextColor('basic-100');
          break;
        case "Fair price":
          setTheme('green');
          setBcgColor('#17A06E');
          setCircleColor('#4149DF');
          setTextColor('basic-100');
          break;
        case "Increased price":
          setTheme('green');
          setBcgColor('#17A06E');
          setCircleColor('#4149DF');
          setTextColor('basic-800');
          break;
        case "High price":
          setTheme('blue');
          setBcgColor('#4149DF');
          setCircleColor('#4149DF');
          setTextColor('basic-100');
          break;
        default:
          setTheme('blue');
          setBcgColor('grey');
          setCircleColor('black');
          setTextColor('basic-100');
      }
      setIsVisible(true);
    }
  }, [data]);

  return (
    isVisible ? 
    <ScrollingProvider>
      <div className='app'>
        <Top/>
        <Header
          theme={theme}
          data={data}/>
        {
        //<Body/>
        }
        <Education
          theme={theme}/>
        {
        //<FooterCTA/>
        }
        <Footer/>
      </div>
    </ScrollingProvider>
    : null
  );
}

export default App;