import React from 'react';
import './Footer.scss';
import logo from '../../image/logo.svg';
import facebook from '../../image/facebook.svg';
import linkedin from '../../image/linkedin.svg';
import Text from '../text/Text';

const Footer = ({
}) => {
  return (
    <div className='footer'>
      <div className='footerContent'>
        <img src={logo} className="footerLogo" alt="Instacar logo" />
        <Text category='s2' status='basic-100'>
          2022 © MINDEN JOG FENNTARTVA.
        </Text>
        <div>
          <a className='impressBtn'>
            <Text category='s2' status='basic-100'>
              IMRESSZUM
            </Text>
          </a>
          <a href='https://instacar.app/letoltes/Instacar-adatkezelesi-tajekoztato.pdf' className='dataStatementBtn'>
            <Text category='s2' status='basic-100'>
              ADATKEZELÉSI NYILATKOZAT
            </Text>
          </a>
        </div>
        <div>
          <a href='https://www.facebook.com/instacarofficial'>
            <img src={facebook} className="facebookLogo" alt="facebook logo" />
          </a>
          <a href='https://www.linkedin.com/company/73218803/'>
            <img src={linkedin} className="linkedinLogo" alt="linkedin logo" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer